// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-benchtops-tsx": () => import("./../../../src/pages/products/benchtops.tsx" /* webpackChunkName: "component---src-pages-products-benchtops-tsx" */),
  "component---src-pages-products-door-styles-tsx": () => import("./../../../src/pages/products/door-styles.tsx" /* webpackChunkName: "component---src-pages-products-door-styles-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-internal-external-doors-tsx": () => import("./../../../src/pages/products/internal-external-doors.tsx" /* webpackChunkName: "component---src-pages-products-internal-external-doors-tsx" */),
  "component---src-pages-products-joinery-tsx": () => import("./../../../src/pages/products/joinery.tsx" /* webpackChunkName: "component---src-pages-products-joinery-tsx" */),
  "component---src-pages-products-mouldings-tsx": () => import("./../../../src/pages/products/mouldings.tsx" /* webpackChunkName: "component---src-pages-products-mouldings-tsx" */),
  "component---src-pages-timber-tsx": () => import("./../../../src/pages/timber.tsx" /* webpackChunkName: "component---src-pages-timber-tsx" */)
}

